import { LegDetailDto } from '@shared/dto/requests.dto'
import { api } from '@app/utils/api/api'
import { useEffect, useState } from 'react'
import { LatLng } from 'leaflet'
import { useQuery } from '@tanstack/react-query'

interface Props {
  legs: LegDetailDto[]
}

export interface LegPathPoints {
  [key: string]: LatLng
}

export const useMapFlightPoints = ({ legs }: Props) => {
  const [mapPointsData, setMapPointsData] = useState<LegPathPoints | null>(null)
  const [legData, setLegData] = useState<LegDetailDto[]>([])

  const airwayLegs = legs
    .map((leg) => leg.airway_id)
    .filter((airwayId) => airwayId !== null && airwayId !== undefined)

  // Fetch airway data if there are airway IDs
  const { data: airwayData, isLoading: isAirwayLoading } = useQuery(
    ['airways', airwayLegs],
    () => api.getMultipleAirways(airwayLegs).then((res) => res.data),
    { enabled: airwayLegs.length > 0 },
  )

  useEffect(() => {
    setLegData(legs)
    const points: LegPathPoints = {}

    legs.forEach((leg) => {
      if (
        airwayData &&
        airwayData.some((airway) => airway.id === leg.airway_id)
      ) {
        const airway = airwayData.find((airway) => airway.id === leg.airway_id)
        if (airway?.path) {
          points[leg.id] = airway.path.map((path) => [
            path.la,
            path.lo,
          ]) as unknown as LatLng
        }
      } else {
        const startPoint = [
          leg.departure_airport.latitude,
          leg.departure_airport.longitude,
        ]
        const endPoint = [
          leg.arrival_airport.latitude,
          leg.arrival_airport.longitude,
        ]
        points[leg.id] = [startPoint, endPoint] as unknown as LatLng
      }
    })
    setMapPointsData(points)
  }, [legs, airwayData])

  return {
    isLoading: airwayLegs.length === 0 ? false : isAirwayLoading,
    legs: legData,
    mapPointsData: mapPointsData,
  }
}

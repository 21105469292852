export enum RoundingTypes {
  Up = 'up',
  Down = 'down',
  ToNearest = 'to-nearest',
  None = 'none',
}

export enum RoundingMultiples {
  Tenths = 'tenths',
  Fifties = 'fifties',
  Hundreds = 'hundreds',
  Thousands = 'thousands',
  None = 'none',
}

export enum HourlyVariableAdjustmentUnits {
  FlatFee = 'flat-fee',
  Percentage = 'percentage',
}

export enum AircraftCategory {
  Piston = 10,
  TurboProp = 20,
  EntryLevelJet = 30,
  LightJet = 40,
  SuperLightJet = 50,
  MidsizeJet = 60,
  SuperMidsizeJet = 70,
  HeavyJet = 80,
  UltraLongRange = 90,
  VIPAirliner = 100,
}
